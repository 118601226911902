import { default as ai_45form_45builderQHtygzVK00Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/ai-form-builder.vue?macro=true";
import { default as editpepxohpbzHMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexMAHnARwJwoMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/index.vue?macro=true";
import { default as index8ec43ZEWZ5Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexpUmgWJxGxgMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as share8urkjwt421Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsBf6CrbM9xKMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsfHXZIM4g3gMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as show2NjtmGpu4qMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestuIIFLNkHsBMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/create/guest.vue?macro=true";
import { default as indexb4FGeKhTOiMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/create/index.vue?macro=true";
import { default as homexm6NkhyN83Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/home.vue?macro=true";
import { default as indexwUzZnLSwg3Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/index.vue?macro=true";
import { default as loginfQG1fwNXELMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/login.vue?macro=true";
import { default as callback5n7FgAwUWqMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/oauth/callback.vue?macro=true";
import { default as emailmyb7PUNULMMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/password/email.vue?macro=true";
import { default as _91token_93TDJShyKBGPMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingxlXwTAcAyaMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyim8qG6NxDiMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/privacy-policy.vue?macro=true";
import { default as registercNSfzLUokmMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/register.vue?macro=true";
import { default as access_45tokensyUpPONuifEMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/access-tokens.vue?macro=true";
import { default as account64sGSWqr64Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/account.vue?macro=true";
import { default as adminti1GbsT221Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/admin.vue?macro=true";
import { default as billingh4myC2gsZAMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/billing.vue?macro=true";
import { default as connectionsHLywEE5WgHMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/connections.vue?macro=true";
import { default as indexv9SVfSFgAbMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/index.vue?macro=true";
import { default as passwordPX01fu9BCjMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/password.vue?macro=true";
import { default as profilem5jLLfMQdMMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/profile.vue?macro=true";
import { default as workspace3vZjYPQpw2Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/workspace.vue?macro=true";
import { default as settingsYDILzL0QT5Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/settings.vue?macro=true";
import { default as errorAnAxgZwPoLMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/subscriptions/error.vue?macro=true";
import { default as success88MAqSo4wqMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93irI7svI1sFMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/[slug].vue?macro=true";
import { default as indexq3JhcDQBFrMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93u1RePIOPrzMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesXlJ5h3vmhZMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93DW37N5IA8LMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsdYXt7hEIa9Meta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentials6CrfBG9OKHMeta } from "/var/www/api-formforge.worldbriefings.com/public/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderQHtygzVK00Meta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editpepxohpbzHMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: show2NjtmGpu4qMeta?.name,
    path: "/forms/:slug()/show",
    meta: show2NjtmGpu4qMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: index8ec43ZEWZ5Meta?.redirect,
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexpUmgWJxGxgMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: share8urkjwt421Meta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsBf6CrbM9xKMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionsfHXZIM4g3gMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestuIIFLNkHsBMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexb4FGeKhTOiMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homexm6NkhyN83Meta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginfQG1fwNXELMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailmyb7PUNULMMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93TDJShyKBGPMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingxlXwTAcAyaMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyim8qG6NxDiMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registercNSfzLUokmMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsYDILzL0QT5Meta?.name,
    path: "/settings",
    meta: settingsYDILzL0QT5Meta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: account64sGSWqr64Meta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminti1GbsT221Meta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingh4myC2gsZAMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsHLywEE5WgHMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexv9SVfSFgAbMeta?.redirect,
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordPX01fu9BCjMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profilem5jLLfMQdMMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspace3vZjYPQpw2Meta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorAnAxgZwPoLMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: success88MAqSo4wqMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesXlJ5h3vmhZMeta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsdYXt7hEIa9Meta || {},
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/var/www/api-formforge.worldbriefings.com/public/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]